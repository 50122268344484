import request from '@/utils/request'


// 查询nfc信息列表
export function listNfc(query) {
  return request({
    url: '/door/door-nfc/list',
    method: 'get',
    params: query
  })
}

// 查询nfc信息分页
export function pageNfc(query) {
  return request({
    url: '/door/door-nfc/page',
    method: 'get',
    params: query
  })
}

// 查询nfc信息详细
export function getNfc(data) {
  return request({
    url: '/door/door-nfc/detail',
    method: 'get',
    params: data
  })
}

// 新增nfc信息
export function addNfc(data) {
  return request({
    url: '/door/door-nfc/add',
    method: 'post',
    data: data
  })
}

// 修改nfc信息
export function updateNfc(data) {
  return request({
    url: '/door/door-nfc/edit',
    method: 'post',
    data: data
  })
}

// 删除nfc信息
export function delNfc(data) {
  return request({
    url: '/door/door-nfc/delete',
    method: 'post',
    data: data
  })
}
